import { graphql } from "gatsby";
import * as React from "react";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from "~/components/Layout";
import { ArticleRenderer } from '../components/ArticleRenderer';
import { CaseStudyMasthead } from '../components/flexible/mastheads/CaseStudyMasthead';
import {CaseStudyNewHead} from '../components/flexible/mastheads/CaseStudyNewHead'; // New Component
import { Button } from '../components/elements/buttons/Button';
import Image from '~/components/elements/Image';
import { Link } from "gatsby";
import { createGlobalStyle } from 'styled-components';

export default function CaseStudyPage({
  data: { wpCaseStudy, wp },
}) {
  const slug = wpCaseStudy?.slug; // Slug from GraphQL
  console.log('Case Study Slug:', wpCaseStudy);
  const Headingstyle = {
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '30px',
    marginTop: '30px',
    fontWeight: 700, // numeric value, no quotes needed
    color: '#000',
  };
  const GlobalStyle = createGlobalStyle`
  p {
    font-size: 18px;
    margin-top: 25px !important;
    margin-bottom: 25px !important; 
    color: #000 !important;
  }

  h2 {
      font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 700, // numeric value, no quotes needed,
    color: '#000',
  }
  h3 {
    font-size: 36px;
    margin-bottom:40px;
    line-height: 40px;
}
    .casestudy-article{
    width:65% !important;
    }
    .classpasslogo{
    width: 100%;
    max-width: 200px;
    height: auto;
    max-height: 75px;
}
    h1.headerheading {
    font-size: 48px;
    line-height: 48px;
    margin-top: 16px !important;
}
    h3.statstitle {
    font-size: 27px;
    line-height: 40px;
    font-family: 'Favorit Pro';
    font-weight: 400;
}
    p.statsval {
    font-size: 16px;
    line-height: 22px;
    color:#FFF;
    margin-top:0px !important;
}
    p.statsval {
    font-size: 16px;
    line-height: 22px;
    color: #FFF !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    }
    .author-quote {
    width: 85% !important;
}
    img.author-image {
    width: 20%;
    height: auto;
    aspect-ratio: 1;
    filter: grayscale();
    max-height: 100%;
}
    .yellowbtn {
        color: #1c1b17e6;
        background-color: #e4f222;
        transition: background-color .2s ease-in;
        position: absolute;
        margin-right: 0%;
        padding: 12px !important;
        margin-top: 0px;
}
        .emailfield{
            border: unset;
    height: 60px;
        }
    .border-color{
    border-color: #1c1b171a !important;
    }
    .author-quote > p > p {
    font-size: 32px !important;
    line-height: 40px;
}
    .bullet-points.casestudy-bullets > p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
    .bullet-points.casestudy-bullets > p > a {
   text-decoration: underline;
    }
   .d-none{
   visibility:hidden;
   }
   .logopadding{
   padding-top:14rem !important;
   }
    `;
  return (
   
    <Layout wp={wp}>
      <Seo post={wpCaseStudy} />
      <GlobalStyle />
      {/* Conditional Rendering Based on Slug */}
      {slug === 'casestudytestpage' ? (
        <CaseStudyNewHead {...wpCaseStudy?.postTypeCaseStudy?.caseStudySummary} />
      ) : (
        
        <CaseStudyMasthead {...wpCaseStudy?.postTypeCaseStudy?.caseStudySummary} />
      )}

      <section className="section-contained xl:!w-[1360px] case-study-details font-body section-top section-bot bg-white rounded-3xl p-6 lg:py-26 lg:px-20">

        <div className="flex flex-col lg:flex-row gap-y-10 gap-x-8 lg:gap-36 relative">
            
            {/* Conditional Rendering Based on Slug */}
            {slug === 'casestudytestpage' ? (
            
            <article className="flex-auto casestudy-article">
        <img src="/video.webp"></img>
        <div className="prblem-sec">
          <h2 className="d-none" style= {Headingstyle}>{wpCaseStudy?.postTypeCaseStudy?.theProblemHeading}</h2>
          <p  dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.theProblemContent }} />

          <h2 style= {Headingstyle}>{wpCaseStudy?.postTypeCaseStudy?.theProblemHeading}</h2>
          <p  dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.theProblemContent }} />

          <h2 style= {Headingstyle}>{wpCaseStudy?.postTypeCaseStudy?.theSolutionHeading}</h2>
          <p  dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.theSolutionContent }} />

          <h2 style={Headingstyle}>{wpCaseStudy?.postTypeCaseStudy?.theResultHeading}</h2>
          <p  dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.theResultContent }} />

        </div>
        </article>

) : (
  <article className="flex-auto">
    <ArticleRenderer
      prefix="CaseStudy_Posttypecasestudy_ArticleContent_"
      content={wpCaseStudy?.postTypeCaseStudy?.articleContent}
    />
  </article>
)}

          
<div className="lg:max-w-[260px]">
  <div className="top-[4.5rem]">
    <ul className="grid grid-cols-2 lg:block">
      {/* Social Media Icons Section */}
      <li className="mb-8 col-span-2">
        <div className="flex gap-4 items-center">
        <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.facebookLink} target="_blank" rel="noopener noreferrer">
            <img src="/fb.svg" alt="Facebook" className="w-6 h-6" />
          </a>
          <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.twitterLink} target="_blank" rel="noopener noreferrer">
            <img src="/twitter.svg" alt="Twitter" className="w-6 h-6" />
          </a>
          <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.linkedinLink} target="_blank" rel="noopener noreferrer">
            <img src="/linkedin.svg" alt="LinkedIn" className="w-6 h-6" />
          </a>
          {/* <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.facebookLink} target="_blank" rel="noopener noreferrer">
            <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.facebookImage.localFile.publicURL} alt="Facebook" className="w-6 h-6" />
          </a>
          <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.twitterLink} target="_blank" rel="noopener noreferrer">
            <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.twitterImage.localFile.publicURL} alt="Twitter" className="w-6 h-6" />
          </a>
          <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.linkedinLink} target="_blank" rel="noopener noreferrer">
            <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.linkedinImage.localFile.publicURL} alt="LinkedIn" className="w-6 h-6" />
          </a> */}
        </div>
      </li>

      {/* Existing Company Menu */}
      <li className="mb-8">
        <h3 className="uppercase t-12-bold mb-1">Company</h3>
        <span>{wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.company}</span>
      </li>
      <li className="mb-8">
        <h3 className="uppercase t-12-bold mb-1">Industry</h3>
        <span>{wpCaseStudy?.postTypeCaseStudy?.industry}</span>
      </li>
      <li className="mb-8">
        <h3 className="uppercase t-12-bold mb-1">Company size</h3>
        <span>{wpCaseStudy?.postTypeCaseStudy?.companySize}</span>
      </li>
      <li className="mb-8 col-span-2">
        <h3 className="uppercase t-12-bold mb-1">Key pain points</h3>
        <div
          className="bullet-points casestudy-bullets"
          dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.keyPoints }}
        />
      </li>
      {wpCaseStudy?.postTypeCaseStudy?.rampProductsUsed && (
        <li className="mb-8 col-span-2">
          <h3 className="uppercase t-12-bold mb-1">Ramp products used</h3>
          <div
            className="bullet-points casestudy-bullets"
            dangerouslySetInnerHTML={{
              __html: wpCaseStudy?.postTypeCaseStudy?.rampProductsUsed,
            }}
          />
        </li>
      )}
      {wpCaseStudy?.postTypeCaseStudy?.aboutTheCompany && (
        <li className="mb-8 col-span-2">
          <h3 className="uppercase t-12-bold mb-1">About the company</h3>
          <div
            className="bullet-points casestudy-bullets"
            dangerouslySetInnerHTML={{
              __html: wpCaseStudy?.postTypeCaseStudy?.aboutTheCompany,
            }}
          />
        </li>
      )}
    </ul>
    <Button
      type="button"
      color="coral"
      link={wpCaseStudy?.postTypeCaseStudy?.ctaLink}
    />
  </div>
</div>

        </div>
      </section>

      <ul className="container !max-w-[1200px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-12 pb-7">
        {wpCaseStudy?.postTypeCaseStudy?.recommended?.map((post, i) => {
          return <RecommendedPost key={i} post={post.recommendedItem} />;
        })}
      </ul>
    </Layout>
  );
}

const RecommendedPost = ({ post }) => {
  return (
    <li className="bg-white rounded-[2rem] border-[1px] border-black/10">
      <Link to={post?.uri} className="block p-6 md:p-8 lg:p-[40px] hover:opacity-50 transition-all duration-200">
        <div className="mb-10">
          {post?.featuredImage && (
            <Image
              className="aspect-[302/214] rounded-[20px]"
              objectFit="cover"
              objectPosition="center"
              image={post?.featuredImage?.node}
            />
          )}
          {!post?.featuredImage && <div className="aspect-[302/214] rounded-[20px] bg-paleBlue" />}
        </div>
        <div className="max-w-[75px] h-[1.5rem] mb-6">
          <Image
            className="w-full h-full object-contain object-center"
            image={post?.postTypeCaseStudy?.caseStudySummary?.logo}
          />
        </div>
        <h3 className="t-heading-30 mb-8">{post?.title}</h3>
        <span className="text-coral t-19">Read story</span>
      </Link>
    </li>
  );
};

export const pageQuery = graphql`
  query CaseStudy($id: String!) {
    wpCaseStudy(id: { eq: $id }) {
      ...SEOCaseStudy
      ...GeneratedWpCaseStudy
      slug # Fetching the slug
    }
    wp {
      ...GeneratedWp
      header {
        optionsHeader {
          menu {
            link {
              url
              title
            }
            showSubmenu
            submenu {
              type
              withPostMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                    description
                  }
                }
              }
              withImageMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                rightColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                image {
                  ...Image
                }
              }
            }
          }
        }
      }
    }
  }
`;
