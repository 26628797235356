import React from 'react';
import Image from '~/components/elements/Image';
import yellowShape from '~/assets/images/yellow-shape.svg';
import { motion } from 'framer-motion';

export const CaseStudyNewHead = (props) => {
  const { heading, subheading, description, previewImage, quote, logo, name, role, company, backgroundImage, authorImage} = props
  const stats = [
    {
      title: "10x faster fundings",
      value: "Cut processing time from 2 days to 2 hours",
      description: "Revenue processed monthly",
    },
    {
      title: "Enhanced visibility",
      value: "across warehouse lines",
      description: "Year over year growth",
    },
    {
      title: "1 day saved per employee",
      value: "on warehouse management processes",
      description: "Support coverage",
    },
  ];
  return (
    <div className="w-full min-h-screen bg-white">
   <header className="relative h-[80vh] w-full">
 
  <div className="absolute inset-0">
   
    <img
      src={backgroundImage.localFile.publicURL}
      alt="Professional in modern office setting"
      className="w-full h-full object-cover object-center"
    />
    <div className="customer-hero__black-gradient"></div>

    <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
  </div>

  <div className="relative z-10 max-w-6xl mx-auto logopadding px-4 pt-24">
  
    <div className="flex gap-2 items-center mb-4">
      <div className="flex items-center space-x-2">
        <img
          src={logo.localFile.publicURL}
          alt="Classpass logo"
          className="h-8 classpasslogo"
        />
      </div>
    </div>

  
    <h1 className="text-4xl md:text-6xl font-bold text-white max-w-3xl mb-16 headerheading">
      {subheading}
    </h1>

    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
      {stats.map((stat, index) => (
        <div
          key={index}
          className={`p-3 ${index !== stats.length - 1 ? "md:border-r border-white/20" : ""}`}
        >
          <h3 className="text-white/80 text-sm mb-2 statstitle">{stat.title}</h3>
          <p className="text-3xl font-bold text-white mb-2 statsval">{stat.value}</p>
        </div>
      ))}
    </div>
  </div>
</header>

   
    <div className="bg-[#efefef]">
    <section className="max-w-6xl mx-auto px-4">
      {/* Quote Part */}
      <div className="flex gap-8 items-start py-16">
        <img
          src={authorImage.localFile.publicURL}
          alt="Quote author"
          className="w-20 h-20 rounded-full object-cover author-image"
        />
        <div className='author-quote'>
          <p className="text-2xl text-gray-700 mb-6 leading-relaxed" dangerouslySetInnerHTML={{__html:quote}}></p>
          <p className="" dangerouslySetInnerHTML={{__html:quote}}></p>
          <div>
            {/* <p className="font-semibold text-gray-900">{name}</p> */}
            <p className="text-sm text-gray-600">
            {`${name ? `With ${name},`:``} ${role ? `${role}` : ''} ${company ? `at ${company}`:``}`}
            </p>
          </div>
        </div>
      </div>
      {/* Border */}
      <div className="border-b border-gray-300 border-color"></div>
      {/* Two Column Section */}
      <div className="py-16 grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
        {/* Left Column */}
        <div>
          <h2 className="text-3xl font-bold mb-4">
          See insights on how 25k+ customers spent on Ramp in 2024
          </h2>
         
        </div>
        {/* Right Column */}
        <div>
        <form className="relative flex">
  <div className="relative w-full">

    <input
      type="email"
      placeholder="What's your work email?"
      className="w-full px-4 py-3 pr-[20px] emailfield rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
    />
    
   
    <button
      type="submit"
      className="absolute top-1/2 right-2 -translate-y-1/2 px-6 py-2 bg-yellow-400 text-gray-900 yellowbtn rounded-md hover:bg-yellow-500"
    >
      Download free report
    </button>
  </div>
</form>

        </div>
      </div>
    </section>
    </div>
  </div>

    
  )
}


