import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Image from '../elements/Image';
import { CoralCheck } from '../elements/Icon';
import { ButtonArrow } from '../elements/buttons/Button';
import { Logo } from '../elements/Icon';
import { InViewAnimator } from '~/components/elements/Animator';
import { Link } from 'gatsby';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputField from '../forms/fields/InputField';
import SelectField from '../forms/fields/SelectField';
import CheckboxField from '../forms/fields/CheckboxField';

export const SpeakWithUs = (page) => {
  const layout = page?.speakWithUs?.layout || 'v1';
  const featureList = page?.speakWithUs?.featureList;
  const logosList = page?.speakWithUs?.logosList;
  const form = page?.speakWithUs?.form;

  return !layout || layout === 'v1' ? (
    <div className="flex items-center justify-center w-full py-8 xl:min-h-screen xl:min-w-screen md:py-32 xl:py-12">
      <InViewAnimator
        className="container !max-w-[1200px] flex lg:flex-row flex-col !w-full justify-between lg:space-x-6 "
        inner={(controls) => (
          <>
            <LeftCol featureList={featureList} logosList={logosList} controls={controls} />
            <RightCol form={form} controls={controls} />
          </>
        )}
      />
    </div>
  ) : (
    <V2 {...page?.speakWithUs} />
  );
};

export function V2({ form, confirmation, logosList }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  // useEffect(()=>{
  //   const tm =setTimeout(()=>{
  //     setIsSubmitted(!isSubmitted);
  //   }, 3000);
  //   return () => clearTimeout(tm);
  // },[isSubmitted])

  const Confirmation = ({ confirmation }) => {
    if (confirmation?.type === 'redirect' && confirmation?.redirect?.length > 0) {
      return (window.location.href = confirmation?.redirect);
    }

    return (
      <div className="space-y-4 text-center">
        <h2 className="t-40" dangerouslySetInnerHTML={{ __html: confirmation.heading }} />
        <div className="t-16 text-[#071B1EB2]" dangerouslySetInnerHTML={{ __html: confirmation.content }} />
        <div className="pt-5">
          <Link className="btn btn-orange !w-full block" to={confirmation?.button?.url}>
            {confirmation?.button?.title}
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-8 flex items-center justify-center w-full py-8 md:min-h-screen xl:min-w-screen md:py-12 bg-darkestGreen text-white">
      <InViewAnimator
        className="container !max-w-[1200px] flex w-full items-center lg:space-x-6 "
        inner={(controls) => (
          <AnimatePresence exitBeforeEnter={confirmation.type !== 'redirect'} key="form-layout">
            <motion.div
              key={'form'}
              layout
              className={`bg-white text-dark relative rounded-[10px]  w-full ${
                !isSubmitted ? 'max-w-[600px] py-10 px-6 sm:px-10 md:p-12' : 'max-w-[440px] p-[56px]'
              }`}
              style={{
                border: '1px solid rgba(6, 42, 47, 0.08)',
                boxShadow: '0px 8px 10px -6px rgba(0, 0, 0, 0.10), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)',
              }}
            >
              {!isSubmitted ? (
                <motion.div key={'first'} className="w-full">
                  <div className="text-center mb-8 space-y-4">
                    <h1 className="t-40 max-w-[9em] mx-auto" dangerouslySetInnerHTML={{ __html: form.heading }} />
                    <div className="t-16 text-[#071B1EB2]" dangerouslySetInnerHTML={{ __html: form.instructions }} />
                  </div>
                  <div>
                    <SpeakFormV2 setIsSubmitted={setIsSubmitted} submitButtonText={form.submitButtonText} />
                  </div>
                  <div className="pt-10">
                    <div className="-ml-[30px] flex">
                      {logosList.logos.map((item, i) => (
                        <div key={`logo-${i}`} className="pl-[30px] flex-auto">
                          <Image image={item.logo} className={`w-full h-full object-contain object-center`} />
                        </div>
                      ))}
                    </div>
                    {/* <div className="text-center">
                      <div
                        onClick={() => setIsSubmitted(true)}
                        className="cursor-pointer absolute bottom-0 right-0 text-[12px] inline-block rounded-[16px] bg-darkestGreen text-white px-4 py-1.5 hover:opacity-70 transition-opacity duration-200 mx-auto"
                      >
                        Simulate form submit
                      </div>
                    </div> */}
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key={'confirmation'}
                  className="w-full"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.4 } }}
                  exit={{ opacity: 0 }}
                >
                  <svg
                    className="cursor-pointer absolute top-[20px] right-[20px] hover:opacity-80 transition-opacity duration-150"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g>
                      <circle cx="12" cy="12" r="12" fill="#F2F5F1" />
                      <path d="M8 8L16 16" stroke="#001118" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M16 8L8 16" stroke="#001118" stroke-width="1.5" stroke-linecap="round" />
                    </g>
                  </svg>
                  <Confirmation confirmation={confirmation} />
                </motion.div>
              )}
            </motion.div>
          </AnimatePresence>
        )}
      />
    </div>
  );
}

const SpeakFormV2 = ({ setIsSubmitted, submitButtonText }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [result, setResult] = useState(null);

  const assetClassOptions = [
    {
      label: '',
      value: '',
    },
    {
      label: 'Auto',
      value: 'Auto',
    },
    {
      label: 'Consumer Loans',
      value: 'Consumer Loans',
    },
    {
      label: 'Credit Cards',
      value: 'Credit Cards',
    },
    {
      label: 'Equipment',
      value: 'Equipment',
    },
    {
      label: 'Factored Receivables',
      value: 'Factored Receivables',
    },
    {
      label: 'Small and Midsized Business',
      value: 'Small and Midsized Business',
    },
    {
      label: 'REO properties',
      value: 'REO properties',
    },
    {
      label: 'Residential Mortgage',
      value: 'Residential Mortgage',
    },
    {
      label: 'Single Family Rental',
      value: 'Single Family Rental',
    },
    {
      label: 'Solar Loans',
      value: 'Solar Loans',
    },
    {
      label: 'Trade Receivables',
      value: 'Trade Receivables',
    },
    {
      label: 'Residential Transition Loans',
      value: 'Residential Transition Loans',
    },
    {
      label: 'Esoteric',
      value: 'Esoteric',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ];

  const validationSchema = Yup.object({
    full_name: Yup.string().required('Full name is required'),
    email: Yup.string().email('Invalid email address').required('Email address is required'),
    company_website: Yup.string().required('Company website/name is required'),
    asset_class: Yup.string()
      .oneOf(
        assetClassOptions.slice(1).map(({ value }) => value),
        'Please select an option'
      )
      .required('Please select an option'),
    role: Yup.string().required('Your role is required'),
    accept_terms: Yup.bool().oneOf([true], 'Field must be checked'),
  });

  return (
    <Formik
      initialValues={{
        full_name: '',
        email: '',
        company_website: '',
        asset_class: '',
        role: '',
        accept_terms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        const formData = new FormData();

        for (var key in values) {
          formData.append(key, values[key]);
        }

        fetch('https://getform.io/f/7e50b217-11b6-415b-8f80-cea2968b4768', {
          method: 'POST',
          body: formData,
        })
          .then((response) => {
            if (response.status === 200) {
              setIsSubmitted(true);
              setErrorMessage(''); // reset error message
              resetForm();
            }
          })
          .catch((error) => {
            setErrorMessage(error);
          });
      }}
    >
      {(props) => (
        <Form className="w-full grid grid-cols-1 sm:grid-cols-2 gap-[16px] sm:gap-[20px]">
          <fieldset className="">
            <InputField name="full_name" label="Full Name" />
          </fieldset>
          <fieldset className="">
            <InputField name="email" label="Work email" type="email" />
          </fieldset>
          <fieldset className="sm:col-span-2">
            <InputField name="company_website" label="Company website" />
          </fieldset>
          <fieldset className="">
            <SelectField name="asset_class" label="Asset class" options={assetClassOptions} />
          </fieldset>
          <fieldset className="">
            <InputField name="role" label="Role" />
          </fieldset>
          <fieldset className="sm:col-span-2">
            <CheckboxField
              name="accept_terms"
              className="text-[#071B1EB2]"
              label={
                <div className="">
                  I agree with{' '}
                  <Link className="font-semibold hover:underline hover:text-orange" to={`/saas-terms-and-conditions/`}>
                    Setpoint's Terms of Service{' '}
                  </Link>{' '}
                  and{' '}
                  <Link className="font-semibold hover:underline hover:text-orange" to={`/privacy-policy/`}>
                    Privacy Policy.
                  </Link>
                </div>
              }
            />
          </fieldset>

          <div className="pt-4 sm:col-span-2">
            <button className="btn btn-orange w-full text-center" type="submit">
              {submitButtonText || 'Submit'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const LeftCol = ({ featureList, logosList, controls }) => {
  return (
    <div className="flex flex-col  order-last lg:order-first w-full lg:max-w-[320px]">
      <div className="w-[156px] mx-auto lg:ml-0 hidden lg:block">
        <Link className="relative z-[9999] hover:opacity-60 transition-all duration-200 " to={'/'}>
          <Logo />
        </Link>
      </div>
      <div className="border border-borderGrey rounded-[20px] mt-8 md:mt-[50px] px-6 md:px-[28px] py-[30px] max-w-[320px] mx-auto lg:ml-0">
        <h5 className="font-body text-base font-medium mb-[26px] text-center md:text-left">{featureList.heading}</h5>
        <ul className="space-y-[15px]">
          {featureList.item.map((item, i) => (
            <li className="flex space-x-[14px] items-center text-base" key={i}>
              <div className="inline">
                <CoralCheck />
              </div>
              <span>{item.listItem}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-8 lg:mt-auto py-[30px] max-w-[250px] mx-auto lg:ml-0 ">
        <h5 className="font-body text-base font-medium mb-[30px] text-center lg:text-left">{logosList.heading}</h5>
        <div className="grid grid-cols-2 gap-4 lg:gap-8">
          {logosList.logos.map((item, i) => (
            <div key={i} className="w-[105px] h-[30px]">
              <Image image={item.logo} className={`w-full h-full object-contain object-center`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const RightCol = ({ form, controls }) => {
  return (
    <div className="w-full lg:max-w-[836px]">
      <div className="w-[156px] mx-auto lg:ml-0 mb-8">
        <Link className="relative z-[9999] hover:opacity-60 transition-all duration-200 block lg:hidden" to={'/'}>
          <Logo />
        </Link>
      </div>
      <div className="bg-white rounded-[2rem] px-6 md:px-12 xl:pr-[77px] xl:pl-[74px] pt-8 md:pt-16 lg:pt-20 pb-8 md:pb-12 xl:pb-[66px]">
        <header className="flex flex-col-reverse md:flex-row">
          <div className="space-y-[10px] w-full text-center md:text-left">
            <h2 className="t-40 md:max-w-[330px]">{form.heading}</h2>
            <p
              className="t-16 md:max-w-[380px]"
              dangerouslySetInnerHTML={{
                __html: form.instructions,
              }}
            />
          </div>
          <div className="flex flex-row justify-center w-full mb-6 md:mb-8 md:justify-end md:w-1/2">
            {form.icons.map((image, i) => (
              <div className={`w-10 h-10 md:w-[65px] md:h-[65px] ${i < 1 ? '' : '-ml-2 md:-ml-4'}`}>
                <Image image={image.icon} className={`w-full h-full object-contain object-center`} />
              </div>
            ))}
          </div>
        </header>
        <div className="mt-6 md:mt-[42px]">
          <SpeakForm />
        </div>
      </div>
    </div>
  );
};

const SpeakForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [result, setResult] = useState(null);

  const validationSchema = Yup.object({
    'Full Name': Yup.string().required('Please fill out'),
    Email: Yup.string().email('Invalid email address').required('Required'),
    Company: Yup.string(),
    CompanyWebsite: Yup.string(),
    CompanyDesc: Yup.string(),
    'Number of Employees': Yup.number(),
    accept_terms: Yup.bool().oneOf([true], 'Field must be checked'),
  });

  return (
    <Formik
      initialValues={{
        'Full Name': '',
        Email: '',
        Company: '',
        CompanyWebsite: '',
        CompanyDesc: '',
        'Number of Employees': '',
        accept_terms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        // console.log(values);

        const formData = new FormData();

        for (var key in values) {
          formData.append(key, values[key]);
        }

        fetch('https://getform.io/f/228d9162-a439-4a69-8579-aff0d3b6ef56', {
          method: 'POST',
          body: formData,
        })
          .then((response) => {
            if (response.status === 200) {
              setIsSubmitted(true);
            }
          })
          .catch((error) => {
            setErrorMessage(error);
          });

        setIsSubmitted(true);
        setErrorMessage(''); // reset error message
        resetForm();
      }}
    >
      {(props) => (
        <Form className="w-full space-y-2">
          <div className="gap-5 md:flex">
            <fieldset className="my-2 md:w-1/2">
              <InputField name="Full Name" label="Full Name" />
            </fieldset>

            <fieldset className="my-2 md:w-1/2">
              <InputField name="Email" label="Email Address" type="email" />
            </fieldset>
          </div>
          <div className="gap-5 md:flex">
            <fieldset className="my-2 md:w-1/2">
              <InputField name="Company" label="Company" />
            </fieldset>
            <fieldset className="my-2 md:w-1/2">
              <InputField name="CompanyWebsite" label="Company Website" />
            </fieldset>
          </div>
          <div className="gap-5 md:flex">
            <fieldset className="my-2 md:w-1/2">
              <InputField name="CompanyDesc" label="Which best describes your company?" />
            </fieldset>
            <fieldset className="my-2 md:w-1/2">
              <InputField type="number" name="Number of Employees" label="Number of Employees" />
            </fieldset>
          </div>

          <fieldset className="flex items-start w-full gap-3 !mt-5 mb-8 max-w-[575px]">
            <CheckboxField
              name="accept_terms"
              label={
                <div>
                  I accept Setpoint’s{' '}
                  <Link
                    className="underline transition-all duration-200 text-coral hover:text-darkGreen"
                    to={`/privacy-policy/`}
                  >
                    Privacy Policy
                  </Link>{' '}
                  and agree to receive promotional messages from Setpoint about its products and services. You can
                  unsubscribe at any time by clicking on the link at the bottom of our emails.
                </div>
              }
            />
          </fieldset>
          <div className="flex !mt-10 justify-between space-x-3">
            <button
              className="btn btn-coral flex items-center space-x-[9px] whitespace-nowrap justify-center "
              type="submit"
            >
              <span>Submit message</span>
              <ButtonArrow color={'coral'} arrowFill={false} />
            </button>
            {isSubmitted && (
              <div className="t-16 max-w-[395px]">
                <span className="font-bold">Thanks for your message. </span> A member of the Setpoint team will be in
                touch.
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
